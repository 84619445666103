import React from 'react'
import styled from 'styled-components'

interface WrapperProps {
    className?: string
    children: React.ReactNode
}

function Wrapper({ className, children }: WrapperProps) {
    return <Container className={className}>{children}</Container>
}

export default Wrapper

const Container = styled.div`
    position: relative;
    background-color: white;
    width: 500px;
    padding: 60px 100px;
    @media (max-width: 991px) {
        padding: 40px;
    }
`
