import React from 'react'
import ReactDOM from 'react-dom'
import './scss/index.scss'

import App from './App'
import reportWebVitals from './reportWebVitals'
import axios from 'axios'

// axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*'
axios.defaults.withCredentials = true
axios.defaults.baseURL = `${process.env.REACT_APP_SERVER_BASE_URL}`

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
