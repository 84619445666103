import React from 'react'
import styled from 'styled-components'
import CheckboxChecked from 'images/checkbox-checked.svg'
import CheckboxUnchecked from 'images/checkbox-unchecked.svg'

interface CheckboxProps {
    id: string
    children: React.ReactNode
}

function Checkbox({ id, children }: CheckboxProps) {
    return (
        <Wrapper>
            <CheckboxInput id={id} type='checkbox' />
            <Label htmlFor={id}>{children}</Label>
        </Wrapper>
    )
}

export default Checkbox

const Wrapper = styled.div`
    position: relative;
`

const Label = styled.label`
    display: flex;
    align-items: center;
    cursor: pointer;
    &::before {
        content: '';
        display: block;
        background: url(${CheckboxUnchecked}) no-repeat;
        background-size: 24px 24px;
        width: 24px;
        height: 24px;
        margin-right: 8px;
    }
`

const CheckboxInput = styled.input`
    display: none;
    &:checked + ${Label} {
        &::before {
            background: url(${CheckboxChecked}) no-repeat;
            background-size: 24px 24px;
            width: 24px;
            height: 24px;
        }
    }
`
