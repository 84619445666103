import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import axios from 'axios'
import styled from 'styled-components'
import Cookies from 'universal-cookie'
import Input from 'components/Input'
import Button from 'components/Button'
import Wrapper from 'components/Wrapper'

const onLogout = async () => {
    try {
        if (process.env.NODE_ENV === 'production') {
            await axios.post(`/logout`, undefined, {
                withCredentials: true,
            })
        } else {
            const cookies = new Cookies()
            cookies.remove(`${process.env.REACT_APP_SID}`)
        }
        window.location.href = '/login'
    } catch (err: any) {
        Swal.fire({ icon: 'error', title: '로그아웃 실패', text: err.detail })
    }
}

interface MyInfo {
    email: string
    id: number
    nickname: string
    phone: string
    username: string
}

const fetchMyInfo = async () => {
    const res = await axios.get(`/v1/users/me`)
    return res.data
}

function MyInfoPage() {
    const [myInfo, setMyInfo] = useState<MyInfo | null>(null)

    useEffect(() => {
        const fetchData = async () => {
            try {
                const info = await fetchMyInfo()
                setMyInfo(info)
            } catch (err: any) {
                Swal.fire({ icon: 'error', title: '정보를 불러오지 못했습니다!', text: err.detail })
            }
        }

        fetchData()
    }, [])

    return (
        <Container>
            <Wrapper>
                <Title>내정보관리</Title>
                <Input label='아이디' type='text' disabled value={myInfo?.username} />
                <Input label='이름' type='text' value={myInfo?.nickname} disabled />
                <Input label='휴대폰번호' type='text' value={myInfo?.phone} disabled />
                <Input label='이메일' type='text' value={myInfo?.email} disabled />
                <Button label='로그아웃' disabled={false} onClick={onLogout} />
            </Wrapper>
        </Container>
    )
}

export default MyInfoPage

const MyInfoGrid = styled(Wrapper)`
    display: grid;
    grid-template-columns: 300px 300px;
    gap: 20px 40px;
    align-items: center;
    width: unset;
    @media (max-width: 991px) {
        grid-template-columns: 1fr;
        width: 100%;
    }
`

const Title = styled.h1`
    font-size: 24px;
    font-weight: bold;
    color: black;
    grid-column: -1/1;
    padding-bottom: 20px;
    @media (max-width: 991px) {
        display: block;
    }
`
const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 60px;
    @media (max-width: 991px) {
        padding-top: 0;
    }
`
