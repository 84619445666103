import React from 'react'
import { ReactSVG } from 'react-svg'
import styled, { css } from 'styled-components'

interface IconProps {
    name: string
    color?: string
    stroke?: string
    size?: string
    transform?: string
}

function Icon({ name, color, stroke, size, transform }: IconProps) {
    return <SVG src={`/icons/${name}.svg`} color={color} stroke={stroke} size={size} transform={transform} />
}

export default Icon

const SVG = styled(ReactSVG)<IconProps>`
    svg {
        fill: transparent;
        ${({ size }) =>
            size &&
            css`
                width: ${size};
                height: ${size};
            `}
        ${({ transform }) =>
            transform &&
            css`
                transform: ${transform};
            `}
    path {
            ${({ color }) =>
                color &&
                css`
                    fill: ${color};
                `}
        }
        path {
            ${({ stroke }) =>
                stroke &&
                css`
                    stroke: ${stroke};
                `}
        }
    }
`
