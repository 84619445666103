import React from 'react'
import styled from 'styled-components'
import BackArrow from 'images/ic_backArrow.svg'

interface BackButtonProps {
    className?: string
    onClick?: () => void
}

function BackButton({ className, onClick }: BackButtonProps) {
    return (
        <Btn className={className} onClick={onClick}>
            <img src={BackArrow} alt='back' />
        </Btn>
    )
}

export default BackButton

const Btn = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    appearance: none;
    outline: none;
    background: none;
    cursor: pointer;
    margin: 0 4px;
    font-family: inherit;

    background-color: #123693;
    width: 64px;
    height: 64px;
    border-radius: 100vh;
`
