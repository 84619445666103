import React from 'react'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import SignupComplete from './components/SignupComplete'
import { getParam, isLoginValidate } from './lib/lib'
import Error from './pages/Error'
import Login from './pages/Login'
import MyInfo from './pages/MyInfo'
import PasswordChange from './pages/PasswordChange'
import SignUp from './pages/SignUp'

interface PrivateRouteProps {
    component: any
    exact?: boolean
    path: string
}

function PrivateRoute(props: PrivateRouteProps) {
    const { component: Component, ...rest } = props

    return (
        <Route {...rest} render={(props) => (isLoginValidate() ? <Component {...props} /> : <Redirect to="login" />)} />
    )
}
function App() {
    return (
        <BrowserRouter>
            <Switch>
                <Route
                    exact
                    path="/"
                    render={(props) => (isLoginValidate() ? <Redirect to="my_info" /> : <Redirect to="login" />)}
                />
                <Route
                    path="/create_accounts"
                    exact
                    render={(props) => (isLoginValidate() ? <Redirect to="my_info" /> : <SignUp {...props} />)}
                />

                <Route
                    path="/login"
                    render={(props) => (isLoginValidate() && !getParam('next') ? <Redirect to="my_info" /> : <Login />)}
                />
                <PrivateRoute path="/my_info" component={MyInfo} />
                <PrivateRoute path="/change_password" component={PasswordChange} />
                {/* <Route path="*" component={Error} /> */}
            </Switch>
        </BrowserRouter>
    )
}

export default App
