import Swal from 'sweetalert2'
import axios from 'axios'
import styled from 'styled-components'
import Cookies from 'universal-cookie'
import { Formik } from 'formik'
import queryString from 'query-string'
import { getParam } from 'lib/lib'
import Illustration from 'images/login-illustration.svg'
import { Link, useLocation } from 'react-router-dom'
import Logo from 'images/synergy-logo-full.svg'
import Button from 'components/Button'
import Checkbox from 'components/Checkbox'
import LoginInput from 'components/LoginInput'

const onLogin = async (data: { username: string; password: string }) => {
    const res = await axios.post(`/login`, { username: data.username, password: data.password })
    return res.data
}

function Login() {
    const location = useLocation()
    const query = queryString.parse(location.search)

    return (
        <LoginContainer>
            <LeftCol>
                <img className='logo' src={Logo} alt='' />
                <img className='illustration' src={Illustration} alt='' />
            </LeftCol>
            <Divider />
            <RightCol>
                <Formik
                    initialValues={{
                        username: query.name ? (query.name as string) : '',
                        password: query.pass ? (query.pass as string) : '',
                    }}
                    validate={(values) => {
                        const errors: Record<string, string> = {}
                        if (values.username === '') {
                            errors.username = '아이디를 입력해주세요.'
                        } else if (values.password === '') {
                            errors.password = '비밀번호를 입력해주세요.'
                        }
                        return errors
                    }}
                    validateOnChange={false}
                    validateOnBlur={false}
                    onSubmit={(data, { setSubmitting, setStatus }) => {
                        setStatus('')
                        setSubmitting(true)
                        onLogin(data)
                            .then((res) => {
                                if (res.is_success) {
                                    if (process.env.NODE_ENV === 'development') {
                                        const cookie = new Cookies()
                                        cookie.set(`${process.env.REACT_APP_SID}`, 'temp')
                                    }
                                    if (getParam('next')) {
                                        window.location.href = `${process.env.REACT_APP_SERVER_BASE_URL}${getParam(
                                            'next',
                                        )}`
                                    } else if (document.referrer) {
                                        window.location.href = document.referrer
                                    } else {
                                        window.location.href = '/my_info'
                                    }
                                } else {
                                    setStatus(res.result_message || '아이디 또는 비밀번호를 확인해주세요.')
                                }
                            })
                            .catch((err) => {
                                Swal.fire({
                                    icon: 'error',
                                    title: '로그인 실패',
                                    text: err.message || '에러가 발생하였습니다.',
                                    customClass: { container: 'swal' },
                                })
                                setStatus(err.message || '에러가 발생하였습니다.')
                            })
                        setSubmitting(false)
                    }}>
                    {({ values, handleChange, handleSubmit, isSubmitting, errors, touched, status, isValid }) => (
                        <form onSubmit={handleSubmit}>
                            <LoginTitle>통합로그인</LoginTitle>
                            <LoginInput
                                name='username'
                                type='text'
                                placeholder='아이디'
                                icon='ic_user'
                                value={values.username}
                                errorMessage={errors.username && touched.username ? errors.username : undefined}
                                onChange={handleChange}
                            />
                            <LoginInput
                                name='password'
                                type='password'
                                placeholder='비밀번호'
                                icon='ic_lock'
                                value={values.password}
                                errorMessage={errors.password && touched.password ? errors.password : undefined}
                                onChange={handleChange}
                            />
                            <LoginOptions>
                                <Checkbox id='saveID'>아이디저장</Checkbox>
                                <div>
                                    <a href='/#'>아이디/비번찾기</a>
                                    &nbsp;&nbsp;&nbsp;
                                    <Link to='/create_accounts'>회원가입</Link>
                                </div>
                            </LoginOptions>
                            <Button
                                className='login-btn'
                                disabled={isSubmitting}
                                label='로그인하기'
                                errorMessage={isValid && status ? status : undefined}
                            />
                        </form>
                    )}
                </Formik>
            </RightCol>
        </LoginContainer>
    )
}

export default Login

const LoginContainer = styled.div`
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    height: 100vh;
    font-family: 'NanumSquare', sans-serif;
    @media (max-width: 990px) {
        padding: 24px;
    }

    .login-btn {
        margin-top: 0px;
    }

    .swal {
        position: absolute;
        z-index: 9999;
    }
`

const Divider = styled.div`
    width: 1px;
    height: 305px;
    background-color: #e5e5e5;
    @media (max-width: 990px) {
        display: none;
    }
`

const LeftCol = styled.div`
    display: flex;
    flex-direction: column;
    flex: 0 458px;
    height: 314px;
    /* border: 1px solid black; */
    margin-right: 50px;

    .logo {
        width: 160px;
        margin-bottom: 50px;
    }
    .illustration {
        width: 100%;
    }
    @media (max-width: 990px) {
        flex: 0 100%;
        align-items: center;
        margin-right: 0;
    }
`

const RightCol = styled.div`
    flex: 0 320px;
    height: 314px;
    margin-left: 50px;
    font-size: 14px;
    @media (max-width: 990px) {
        flex: 0 100%;
        margin-left: 0;
    }
`

const LoginTitle = styled.h3`
    font-size: 24px;
    font-weight: bold;
    color: #0d1b3f;
    margin-bottom: 36px;
`

const LoginOptions = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 4px 24px 4px;

    a {
        text-decoration: none;
        color: #0e3aad;
    }
`
