import React from 'react'
import styled from 'styled-components'

interface ButtonProps {
    label: string
    disabled?: boolean
    className?: string
    errorMessage?: string
    onClick?: () => void
}

function Button({ label, disabled, className, errorMessage, onClick }: ButtonProps) {
    return (
        <Container className={className}>
            <Btn type='submit' disabled={disabled} onClick={onClick}>
                {label}
            </Btn>
            <ErrorMessage>{errorMessage}</ErrorMessage>
        </Container>
    )
}

export default Button

const Container = styled.div`
    margin-top: 30px;
`

const Btn = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    appearance: none;
    outline: none;
    background: none;
    cursor: pointer;
    margin: 0 4px;
    font-family: inherit;

    background-color: #123693;
    width: 100%;
    color: white;
    font-size: 16px;
    font-weight: bold;
    padding: 16px;
    border-radius: 50vh;

    &:disabled {
        background-color: #d1cfd7;
        cursor: default;
    }
`
const ErrorMessage = styled.div`
    display: block;
    color: red;
    font-size: 14px;
    margin: 12px 0 0 4px;
    text-align: center;
`
