import React, { InputHTMLAttributes } from 'react'
import styled from 'styled-components'
import Icon from 'components/Icon'

interface LoginInputProps extends InputHTMLAttributes<HTMLInputElement> {
    icon: string
    errorMessage?: string
}

function LoginInput({ icon, errorMessage, ...rest }: LoginInputProps) {
    return (
        <InputContainer>
            <input {...rest} autoComplete='off' />
            <div className='icon'>
                <Icon name={icon} size='24px' />
            </div>
            <ErrorMessage>{errorMessage}</ErrorMessage>
        </InputContainer>
    )
}

export default LoginInput

const InputContainer = styled.div`
    margin-bottom: 14px;
    position: relative;
    input {
        position: relative;
        width: 100%;
        font-size: 14px;
        background-color: #f8f8f8;
        border: 1px solid #f8f8f8;
        border-radius: 4px;
        padding: 18px 16px 18px 62px;
        &:focus {
            border: 1px solid #0e3aad;
        }
    }

    .icon {
        position: absolute;
        top: 14px;
        left: 24px;
    }
`

const ErrorMessage = styled.div`
    display: block;
    color: red;
    font-size: 12px;
    margin: 6px 0 0 4px;
    text-align: right;
`
