import React, { InputHTMLAttributes } from 'react'
import styled from 'styled-components'
import ErrorIcon from 'images/ic_error.svg'
import ValidIcon from 'images/ic_valid.svg'

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
    label: string
    errorMessage?: string
    isValid?: boolean
}

function Input({ label, errorMessage, isValid, ...rest }: InputProps) {
    return (
        <Container>
            <Label>{label}</Label>
            <HTMLInput {...rest} error={!!errorMessage} valid={isValid} />
            <ErrorMessage>{errorMessage}</ErrorMessage>
            <Error src={ErrorIcon} error={!!errorMessage} />
            <Valid src={ValidIcon} valid={isValid} />
        </Container>
    )
}

export default Input

const Error = styled.img<{
    error: boolean
}>`
    display: ${(props) => (props.error ? 'block' : 'none')};
    position: absolute;
    top: 34px;
    right: 20px;
`

const Valid = styled.img<{ valid?: boolean }>`
    display: ${(props) => (props.valid ? 'block' : 'none')};
    position: absolute;
    top: 33px;
    right: 20px;
`

const Container = styled.div`
    position: relative;
    margin-bottom: 20px;
`

const Label = styled.div`
    font-size: 14px;
    color: black;
    margin-bottom: 10px;
`

const HTMLInput = styled.input<{ error: boolean; valid?: boolean }>`
    font-size: 14px;
    color: black;
    border: 1px solid #f8f8f8;

    background-color: #f8f8f8;
    padding: 14px 20px;
    width: 100%;
    border-radius: 4px;
    ::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #d1cfd7;
        opacity: 1; /* Firefox */
    }

    ::-ms-input-placeholder {
        /* Microsoft Edge */
        color: #d1cfd7;
    }
    &:focus {
        border: 1px solid #123693;
    }

    ${(props) => props.error && `border: 1px solid #c72522;`}

    ${(props) => props.valid && `border: 1px solid #38D06C;`}
`
const ErrorMessage = styled.div`
    display: block;
    color: red;
    font-size: 12px;
    margin: 6px 0 0 4px;
    text-align: right;
`
