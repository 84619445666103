import axios from 'axios'
import styled from 'styled-components'
import * as Yup from 'yup'
import { Field, Formik } from 'formik'
import React, { useState } from 'react'
import { RouteComponentProps } from 'react-router'
import Input from 'components/Input'
import Button from 'components/Button'
import BackButton from 'components/BackButton'
import SignupComplete from 'components/SignupComplete'
import Swal from 'sweetalert2'

interface SignUp {
    [index: string]: any
    username: string
    password: string
    password_check: string
    nickname: string
    phone: string
    email: string
}

const onSignUp = async (data: SignUp) => {
    const res = await axios.post(`/signup`, data)
    return res.data
}

function Signup({ history, location }: RouteComponentProps) {
    const [success, setSuccess] = useState(false)

    const onChangePhone = (value: string, setFieldValue: Function) => {
        const phone = value
            .replace(/[^0-9]/g, '')
            .replace(/(^02|^0505|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})$/, '$1-$2-$3')
            .replace('--', '-')
        setFieldValue('phone', phone)
    }

    return (
        <Container>
            {success ? (
                <SignupComplete />
            ) : (
                <Wrapper>
                    <Back
                        onClick={() => {
                            history.goBack()
                        }}
                    />
                    <PageTitle>회원가입</PageTitle>
                    <Formik
                        initialValues={{
                            username: '',
                            password: '',
                            password_check: '',
                            nickname: '',
                            phone: '',
                            email: '',
                        }}
                        validationSchema={SignupSchema}
                        validateOnChange={false}
                        onSubmit={(values, { setSubmitting, setStatus }) => {
                            setSubmitting(true)
                            onSignUp(values)
                                .then((res) => {
                                    if (res.is_success === false) {
                                        throw Error(res.result_message)
                                    }
                                    setSuccess(true)
                                })
                                .catch((err) => {
                                    Swal.fire({ icon: 'error', title: err?.message || '에러가 발생하였습니다.' })
                                    setStatus(err?.message || '에러가 발생하였습니다.')
                                })
                            setSubmitting(false)
                        }}>
                        {({ values, handleSubmit, setFieldValue, errors, touched, isSubmitting, status }) => (
                            <form onSubmit={handleSubmit}>
                                <Field
                                    name='username'
                                    label='아이디'
                                    as={Input}
                                    errorMessage={errors.username && touched.username ? errors.username : undefined}
                                    isValid={!errors.username && touched.username ? true : false}
                                />
                                <Field
                                    name='password'
                                    label='비밀번호'
                                    placeholder='8자리 이상, 영문/숫자/특수문자를 모두 사용하여 입력해주세요.'
                                    type='password'
                                    as={Input}
                                    errorMessage={errors.password && touched.password ? errors.password : undefined}
                                    isValid={!errors.password && touched.password ? true : false}
                                />
                                <Field
                                    name='password_check'
                                    label='비밀번호 확인'
                                    as={Input}
                                    type='password'
                                    errorMessage={
                                        errors.password_check && touched.password_check
                                            ? errors.password_check
                                            : undefined
                                    }
                                    isValid={!errors.password_check && touched.password_check ? true : false}
                                />
                                <Field
                                    name='nickname'
                                    label='이름'
                                    as={Input}
                                    errorMessage={errors.nickname && touched.nickname ? errors.nickname : undefined}
                                    isValid={!errors.nickname && touched.nickname ? true : false}
                                />
                                {/* <Field
                                    name='type'
                                    label='회원 유형'
                                    as={SelectComp}
                                    errorMessage={errors.nickname && touched.nickname ? errors.nickname : undefined}
                                    isValid={!errors.nickname && touched.nickname ? true : false}
                                /> */}
                                <Field
                                    name='phone'
                                    label='휴대폰번호'
                                    as={Input}
                                    errorMessage={errors.phone && touched.phone ? errors.phone : undefined}
                                    onKeyUp={() => onChangePhone(values.phone, setFieldValue)}
                                    isValid={!errors.phone && touched.phone ? true : false}
                                />
                                <Field
                                    name='email'
                                    label='이메일'
                                    placeholder='이메일을 입력해주세요'
                                    as={Input}
                                    errorMessage={errors.email && touched.email ? errors.email : undefined}
                                    isValid={!errors.email && touched.email ? true : false}
                                />
                                <Button
                                    disabled={isSubmitting}
                                    label='가입하기'
                                    errorMessage={status ? status : undefined}
                                />
                            </form>
                        )}
                    </Formik>
                </Wrapper>
            )}
        </Container>
    )
}

const SignupSchema = Yup.object().shape({
    username: Yup.string().required('아이디를 입력해주세요.'),
    password: Yup.string()
        .required('비밀번호를 입력해주세요.')
        .matches(
            /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,}$/,
            '8자리 이상, 영문/숫자/특수문자를 모두 사용하여 입력해주세요.',
        ),
    password_check: Yup.string()
        .required('비밀번호를 한 번 더 입력해주세요.')
        .oneOf([Yup.ref('password')], '비밀번호가 일치하지 않습니다.'),
    nickname: Yup.string().required('이름을 입력해주세요.'),
    phone: Yup.string()
        .required('휴대폰번호를 입력해주세요.')
        .matches(/(01[0|1|6|9|7])[-](\d{3}|\d{4})[-](\d{4}$)/g, '잘못된 휴대폰 번호 입니다.'),
    email: Yup.string().required('이메일을 입력해주세요.').email('올바른 이메일 형식이 아닙니다.'),
})

export default Signup

const Back = styled(BackButton)`
    position: absolute;
    top: 60px;
    left: -32px;
    @media (max-width: 991px) {
        display: none;
    }
`

const PageTitle = styled.h1`
    font-size: 24px;
    color: black;
    margin-bottom: 24px;
`
const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 60px;
    @media (max-width: 991px) {
        padding-top: 0;
    }
`

const Wrapper = styled.div`
    position: relative;
    background-color: white;
    width: 500px;
    padding: 60px 100px;
    @media (max-width: 991px) {
        padding: 40px;
    }
`
