import Cookies from 'universal-cookie'

export function getParam(sname: string) {
    const params = rawurldecode(
        // eslint-disable-next-line no-restricted-globals
        location.search.substr(location.search.indexOf('?') + 1),
    )
    return params.slice(params.indexOf('=') + 1)
}

function rawurldecode(str: string) {
    return decodeURIComponent(
        (str + '').replace(/%(?![\da-f]{2})/gi, function () {
            return '%25'
        }),
    )
}

export function isLoginValidate() {
    const cookie = new Cookies()
    const sessionId = cookie.get(`${process.env.REACT_APP_SID}`)
    if (sessionId) {
        return true
    } else {
        return false
    }
}
