import React from 'react'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import CheckCircle from 'images/ic_checkCircle.svg'
import Button from './Button'
import Wrapper from './Wrapper'

function SignupComplete() {
    const history = useHistory()
    return (
        <Wrapper>
            <PageTitle>회원가입</PageTitle>
            <CheckIcon src={CheckCircle} />
            <Title>회원가입이 완료되었습니다.</Title>
            <Para>
                계정이 현재 가입대기 상태입니다.
                <br />
                승인 절차가 완료되면 이메일로 안내드리겠습니다.
                {/* <br />
                아래 버튼을 누르시면 로그인 페이지로 이동합니다. */}
            </Para>
            <Button label='로그인하기' onClick={() => history.goBack()} />
        </Wrapper>
    )
}

export default SignupComplete

const PageTitle = styled.h1`
    font-size: 24px;
    color: black;
    margin-bottom: 24px;
    @media (max-width: 991px) {
        display: none;
    }
`

const CheckIcon = styled.img`
    margin: 0 auto;
    @media (max-width: 991px) {
        margin-top: 50px;
    }
`

const Title = styled.h1`
    font-size: 24px;
    font-weight: bold;
    color: #0e3aad;
    text-align: center;
    padding-top: 25px;
`

const Para = styled.p`
    font-size: 14px;
    color: #777777;
    text-align: center;
    padding-top: 20px;
    line-height: 1.5em;
`
