import React, { useState } from 'react'
import Swal from 'sweetalert2'
import axios from 'axios'
import styled from 'styled-components'
import Input from 'components/Input'
import Button from 'components/Button'
import Wrapper from 'components/Wrapper'

function PasswordChange() {
    const [password, setPassword] = useState<{ old: string; new: string }>({ old: '', new: '' })

    const onChange = (key: string, value: string) => {
        setPassword({ ...password, [key]: value })
    }

    const onSave = async () => {
        try {
            await axios.post(
                `v1/users/me/password`,
                { cur_password: password.old, new_password: password.new },
                { withCredentials: true },
            )
            Swal.fire({ icon: 'success', title: '완료되었습니다.', timer: 3000, timerProgressBar: true })
        } catch (err: any) {
            Swal.fire({ icon: 'error', title: '비밀번호 변경 실패', text: err.message })
        }
    }

    return (
        <Container>
            <Wrapper>
                <Title>비밀번호 변경</Title>
                <Input
                    label='현재 비밀번호'
                    type='password'
                    autoComplete='new-password'
                    value={password.old}
                    onChange={(e) => onChange('old', e.target.value)}
                />
                <Input
                    label='신규 비밀번호'
                    type='password'
                    autoComplete='new-password'
                    value={password.new}
                    onChange={(e) => onChange('new', e.target.value)}
                />
                <Button label='저장' disabled={false} onClick={onSave} />
            </Wrapper>
        </Container>
    )
}

export default PasswordChange

const Title = styled.h1`
    font-size: 24px;
    font-weight: bold;
    color: black;
    grid-column: -1/1;
    padding-bottom: 20px;
    @media (max-width: 991px) {
        display: block;
    }
`
const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 60px;
    @media (max-width: 991px) {
        padding-top: 0;
    }
`
